// Start of Selection
import { useContext } from 'react';
import { Button, Link, IconButton, Tooltip } from "@material-ui/core"
import Loader from "react-loader-spinner"
import GPTBillContext from "../../../contexts/GPTBillContext"
import hiveLogo from "../../../images/hive.png"
export default ({handleSave, gptBill, textractBill, handleReprocess, handleSkip, loading, billIsValid}) => {

  return (
    
    <div className='bill-creation-controls'>
      <Loader
        style={{opacity: loading ? 1 : 0, marginTop: 4, marginRight: "8px"}}
        type="Grid"
        height={36}
        color = 'orange'
        width={36} /> 
      {!!textractBill.status && !textractBill.status.includes("PROCESSED_")
      ? <>
          <Button 
              disabled={loading}
              onClick={_ => handleSkip(textractBill)} 
              className='control-button' 
              variant="outlined">Skip</Button>
          {gptBill && <Button 
              disabled={loading || !billIsValid(gptBill)}
              onClick={_ => handleSave(gptBill, textractBill)} 
              className='control-button' 
              variant="contained">Save Bill</Button>}
        </>
      : <Button 
          disabled={loading}
          onClick={_ => handleReprocess(textractBill)} 
          className="control-button reprocess-button" 
          variant="outlined">Reprocess</Button>}
      <Tooltip title="Report a data parsing issue">
        <Link href="https://forms.hive.com/?formId=q48dyQNLhYQSdvzSy" target="_blank" rel="noopener noreferrer">
          <IconButton>
            <img src={hiveLogo} alt="Hive Logo" style={{ height: '36px' }} />
          </IconButton>
        </Link>
      </Tooltip>
    </div>
  )
}