import { Button, Link, Typography, IconButton} from "@material-ui/core"
import { useEffect, useContext } from "react";
import InvoyageStatus from "./InvoyageStatus";
import {Link as RouterLink} from "react-router-dom"
import { ArrowBackOutlined, ArrowForwardOutlined, Description } from "@material-ui/icons";
import TextractContext from "../../../contexts/TextractContext";
import axiosAuth from "../../../utils/axiosWithAuth";
import AccountSelect from "./form/AccountSelect";
import CompanySelect from "./form/CompanySelect";
import InvoyageTooltip from "./InvoyageTooltip";
import { PictureAsPdf } from "@material-ui/icons";
import { useUser } from "../../../contexts/UserContext";
import Loader from "react-loader-spinner";
import { debounce } from 'lodash';

const BillNavLoader = _ => {
  const {activeTheme} = useUser() 

  return <Loader
    style={{marginTop: 8}}
    color={activeTheme.palette.primary.main}
    type="Oval"
    height={32}
    width={32} 
  /> 
}

export default ({selectedTextractBill,parseWithGPT, reparseAccount, deselectBill,extractBill,selectedConfiguration}) => {
  const {dateRange, setDateRange, currentScaffold, setCurrentScaffold, loadingQueue, snackbar, uams, setUams, utilityCompanies, setUtilityCompanies, accounts, setAccounts, selectedAccount, setSelectedAccount, selectedUtilityCompany, setSelectedUtilityCompany } = useContext(TextractContext)
  const {user} = useUser()
  useEffect(_ => {
    if (selectedTextractBill.utility_company_id && !selectedUtilityCompany) {
      setSelectedUtilityCompany(utilityCompanies.find(uc => uc.id == selectedTextractBill.utility_company_id))
    }

    const billStartDate = new Date(selectedTextractBill.invoice_date || selectedTextractBill.start_date || new Date())
    const rangeStartDate = (new Date(billStartDate)).setMonth(billStartDate.getMonth() - 8);
    const rangeEndDate = (new Date(billStartDate)).setMonth(billStartDate.getMonth() + 4);

    setDateRange({start_date: rangeStartDate, end_date: rangeEndDate})

    axiosAuth().get(`/admin/utility_companies/${selectedTextractBill.utility_company_id || utilityCompanies[0].id}/accounts`)
      .then(accounts => {
        setAccounts(accounts.data)
      })
      .catch(err => console.error(err.response))
  },[selectedTextractBill])
  
  useEffect(_ => {
    if (!selectedUtilityCompany) {
      setAccounts([])
      return
    }
    if (accounts.length && accounts[0].utility_company_id != selectedUtilityCompany.id) {

      axiosAuth().get(`/admin/utility_companies/${selectedUtilityCompany.id}/accounts`)
      .then(accounts => {
        setAccounts(accounts.data)
        
      })
      .catch(err => console.error(err.response))
      
    }
  },[selectedUtilityCompany])

  useEffect(_ => {
    const foundAccount = accounts.find(a => a.id == selectedTextractBill.utility_account_id)
    setSelectedAccount(foundAccount)

    if (!selectedUtilityCompany && foundAccount) {
      
      setSelectedUtilityCompany(utilityCompanies.find(uc => uc.id == foundAccount.id))
    }
    
  }, [accounts, selectedTextractBill])

  useEffect(() => {
    if (selectedAccount) {
      setSelectedUtilityCompany(utilityCompanies.find(uc => uc.id === selectedAccount.utility_company_id));
    } else {
      setSelectedUtilityCompany(utilityCompanies.find(uc => uc.id === selectedTextractBill.utility_company_id));
    }
  }, [selectedAccount, utilityCompanies, selectedTextractBill]);
  
  useEffect(() => {
    const fetchUams = debounce(() => {
      if (selectedAccount && selectedUtilityCompany) {
        axiosAuth().post(`/admin/utility_companies/${selectedUtilityCompany.id}/accounts/${selectedAccount.id}/uams`, dateRange)
          .then(r => {
            setUams(r.data.uams || []);
            setCurrentScaffold(r.data.scaffold);
          })
          .catch(console.error);
      } else if (!selectedAccount) {
        setUams([]);
      }
    }, 300);
  
    fetchUams();
  
    return () => {
      fetchUams.cancel();
    };
  }, [selectedAccount, selectedUtilityCompany, dateRange]);

  return (
    
    <div className="bill-nav">
      <div className='nav-controls'>
        {<RouterLink className='nav-link' to={!!selectedTextractBill.queue.prev ? `/invoyage/${selectedTextractBill.queue.prev}` : "#"}><Button disabled={!selectedTextractBill.queue.prev}><ArrowBackOutlined />&nbsp;Prev</Button></RouterLink>}
        {<RouterLink className='nav-link'to={!!selectedTextractBill.queue.next ? `/invoyage/${selectedTextractBill.queue.next}` : "#"}><Button disabled={!selectedTextractBill.queue.next}>Next <ArrowForwardOutlined /></Button></RouterLink>}
        {loadingQueue && <BillNavLoader /> }
      </div>
      <div className='textract-bill-header'>
        <Typography style={{display: 'inline-block'}} variant="h4"> Invoyage #{selectedTextractBill.id} </Typography>
        {selectedTextractBill.ep_bill && 
         <InvoyageTooltip title="View bill in EP">
          <Link href={`${process.env.REACT_APP_EP_BASE_URL}/admin/utility_accounts/${selectedTextractBill.ep_bill.utility_account_id}/utility_bills/new?edit_bill_id=${selectedTextractBill.ep_bill.id}`} target="_blank"><Description/></Link>
         </InvoyageTooltip>
        }
        <InvoyageStatus title={selectedTextractBill.last_error_cause} status={selectedTextractBill.status} />
        &nbsp;
        <CompanySelect
          isDisabled={selectedTextractBill.status.includes("PROCESSED_")}
        />
        <AccountSelect 
          isDisabled={selectedTextractBill.status.includes("PROCESSED_")}
        />
        &nbsp;&nbsp;
        <div className="invoyage-bill-controls">
        {!user.os ? 
            <>
              <Button variant="contained" onClick={_ => extractBill(selectedTextractBill, selectedConfiguration)}>Extract Bill</Button>&nbsp;
              {!!selectedTextractBill.tables && selectedTextractBill.utility_company_id && !selectedTextractBill.status.includes("PROCESSED_") && 
                <>
                  <Button disabled={snackbar && snackbar.message == "Parsing with GPT..."} variant="contained"  onClick={_ => parseWithGPT(selectedTextractBill)}>
                    {!selectedTextractBill.gpt_bill ? "Parse bill with GPT" : "Redo Bill Parsing"} ⚛ 
                  </Button>
                  &nbsp;
                  {selectedTextractBill.gpt_bill && <Button disabled={snackbar && snackbar.message == "Parsing with GPT..."} variant="contained"  onClick={_ => parseWithGPT(selectedTextractBill, "do_second_pass")}>
                    Do Second Pass ⚛ 
                  </Button>}
                  &nbsp;
                  {selectedTextractBill.gpt_bill && <Button disabled={snackbar && snackbar.message == "Doing Supplementary Pass..."} variant="contained"  onClick={_ => parseWithGPT(selectedTextractBill, "do_supplementary_pass")}>
                    Do Supplemental Pass ⚛ 
                  </Button>}
                  {selectedTextractBill.gpt_bill && <Button disabled={snackbar && snackbar.message == "Parsing with GPT..."} variant="contained"  onClick={_ => reparseAccount(selectedTextractBill.utility_account_id)}>
                    Redo Account Parsing ⚛ 
                  </Button>}
                </>
              }
              &nbsp;
            </>
            : <Button disabled={snackbar && snackbar.message == "Parsing with GPT..."} variant="contained"  onClick={_ => parseWithGPT(selectedTextractBill)}>
            {!selectedTextractBill.gpt_bill ? "Parse bill with GPT" : "Redo Bill Parsing"} ⚛ 
          </Button>
          }
          <InvoyageTooltip title={selectedTextractBill.s3_key && selectedTextractBill.s3_key.split("/").pop()} arrow>
            <Link href={selectedTextractBill.download_url} target="_blank"><IconButton color='primary'><PictureAsPdf /></IconButton></Link>
          </InvoyageTooltip>
        </div>
      </div>
      <div>
        <Button variant='outlined' onClick={_ => deselectBill()}>Close X</Button>
      </div>
    </div>
  ) 
  }